
/* global window */
import React, { Fragment, useCallback, useState, } from 'react';
import { useApolloClient, } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import ReactGA from 'react-ga';
import { useRouter, } from 'next/router';
import { useUser, } from '../components/User/UserDispenser';
import useAbTestGroup from '../hooks/Page/useAbTestGroup';
import { doStatAction, doStatImpression, } from '../components/BI/statutil';
import useWebViewChecker from '../hooks/useWebViewChecker';
import useDocumentEventListener from '../hooks/useDocumentEventListener';

export const GET_USER_AND_PAGE_TYPE = gql`
  query GetUserAndPageType {
    pageType @client
    articleType @client
    articleId @client
    isCloseArticle @client
    isPremiumContent @client
    authorsContentId @client
    platform @client
  }
`;
const GET_USER_PRODUCTS = gql`
  query getUserProducts {
    user {
      products {
        brand
        prodNum
        statusInt
        status
        isTrial
        saleCode
        promotionNum
        connectionType
      }
    }
  }
`;

const impressionSentSet = new Set([]);

const getBIActionWithQuery = (client, router, user) => async (BIaction, sendBeacon = true) => {
  const { data, } = await client.query({
    query: GET_USER_AND_PAGE_TYPE,
    fetchPolicy: 'cache-only',
  });
  const { abTestGroup, } = useAbTestGroup(router);

  if (data) {
    const { gift, zette, } = router.query;

    const miscAdditionalInfo = {};

    if (gift) {
      miscAdditionalInfo.gift = gift;
    }

    if (zette) {
      miscAdditionalInfo.zette = zette;
    }

    const {
      pageType,
      articleId,
      isCloseArticle,
      isPremiumContent,
      authorsContentId,
      platform,
      articleType,
    } = data;

    const paywallType = (router.query || {}).paywallType || null;

    const UserProductsQuery = user.email
      && (await client.query({
        query: GET_USER_PRODUCTS,
      }));
    const userProducts = UserProductsQuery
      && UserProductsQuery.data
      && UserProductsQuery.data.user
      && UserProductsQuery.data.user.products;

    return doStatAction({
      action: BIaction,
      user,
      userProducts,
      pageType,
      articleId,
      authorsContentId,
      isCloseArticle,
      isPremiumContent,
      platform,
      paywallType,
      sendBeacon,
      miscAdditionalInfo,
      articleType,
      abTestGroup,
    });
  }
  return null;
};

const getBIImpressionWithQuery = (client, router, user) => async BIimpression => {
  const impressionKey = JSON.stringify(BIimpression);

  if (impressionSentSet.has(impressionKey)) {
    return Promise.resolve();
  }

  impressionSentSet.add(impressionKey);

  const { data, } = await client.query({
    query: GET_USER_AND_PAGE_TYPE,
    fetchPolicy: 'cache-only',
  });
  const { abTestGroup, } = useAbTestGroup(router);

  if (data) {
    const { gift, zette, } = router.query;

    const miscAdditionalInfo = {};

    if (gift) {
      miscAdditionalInfo.gift = gift;
    }

    if (zette) {
      miscAdditionalInfo.zette = zette;
    }

    const {
      pageType,
      articleType,
      articleId,
      isCloseArticle,
      isPremiumContent,
      authorsContentId,
      platform,
    } = data;
    const paywallType = (router.query || {}).paywallType || null;

    const UserProductsQuery = user?.email
      && (await client.query({
        query: GET_USER_PRODUCTS,
      }));
    const UserProducts = UserProductsQuery
      && UserProductsQuery.data
      && UserProductsQuery.data.user
      && UserProductsQuery.data.user.products;
    return doStatImpression(
      BIimpression,
      user,
      UserProducts,
      pageType,
      articleId,
      authorsContentId,
      isCloseArticle,
      isPremiumContent,
      articleType,
      platform,
      paywallType,
      miscAdditionalInfo,
      abTestGroup,
    );
  }
  return null;
};

const getGaActionWithQuery = () => async gaAction => {
  const { category, action, label, } = gaAction;
  return ReactGA.event({
    category,
    action,
    label,
  });
};

const biActionMapper = new Map([
  [ 'send_comment', 1, ],
  [ 'newsletter_signUp', 9, ],
  [ 'facebook_share', 10, ],
  [ 'whatsApp_share', 11, ],
  [ 'twitter_share', 12, ],
  [ 'other_share', 14, ],
  [ 'messenger_share', 140, ],
  [ 'telegram_share', 166, ],
  [ 'mail_share', 13, ],
  [ 'author_alert', 91, ],
  [ 'zen_mode', 92, ],
  [ 'author_alert_approve', 93, ],
  [ 'text_design_tools', 104, ],
  [ 'next_page', 109, ],
  [ 'breadcrumbs', 110, ],
  [ 'go_to_comments', 111, ],
  [ 'print', 112, ],
  [ 'save_article', 138, ],
  [ 'remove_article', 156, ],
]);

const asyncNoop = async () => null;
const createEventTrackers = (client, isWebView, isLoaded, router, user) => ({
  HtzReactGA: ReactGA,
  biAction: user?.anonymousId && client && (!isWebView || isLoaded) ? getBIActionWithQuery(client, router, user) : asyncNoop,
  biImpression: user?.anonymousId && client && (!isWebView || isLoaded) ? getBIImpressionWithQuery(client, router, user) : asyncNoop,
  gaAction: user?.anonymousId && client && !isWebView ? getGaActionWithQuery(client) : asyncNoop,
  biActionMapper,
  gaMapper: {
    productId: {
      239: 'haaretzcom',
      243: 'haaretz',
      273: 'themarker',
      274: 'dual',
    },
  },
});

function EventTracker({ children, }) {
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);
  const router = useRouter();
  const client = useApolloClient();
  const user = useUser();

  const onLoadElement = useCallback(e => {
    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  const trackers = React.useMemo(
    () => createEventTrackers(client, isWebView, isLoaded, router, user.user),
    [ client, isLoaded, isWebView, router, user.user, ]);

  return (
    <Fragment>
      {typeof children === 'function' ? children(trackers) : null}
    </Fragment>
  );
}

EventTracker.propTypes = {
  /** Indicates rendered JSX wrapped */
  children: PropTypes.func.isRequired,
};

EventTracker.defaultProps = {};


export function useEventTracker() {
  const client = useApolloClient();
  const user = useUser();
  const isWebView = useWebViewChecker();
  const [ isLoaded, setIsLoaded, ] = useState(typeof window === 'undefined' ? false : window.deviceId !== undefined);
  const router = useRouter();

  const onLoadElement = useCallback(e => {
    setIsLoaded(true);
  }, []);

  useDocumentEventListener('loadElement', onLoadElement, false);

  const eventTrackers = React.useMemo(
    () => createEventTrackers(client, isWebView, isLoaded, router, user.user),
    [ client, isLoaded, isWebView, router, user.user, ]);

  return eventTrackers;
}

export default EventTracker;
